@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --background-light: 210 20% 98;

    --foreground: 210 92% 10%;
    --light-foreground: 220 8.9% 46.1%;
    --highlight-foreground: 208 100% 54%;

    --success: 142 76% 36%;
    --success-foreground: 142 76% 30%;

    --error: 0 84.2% 60.2%;
    --error-foreground: 0 84.2% 40%;

    --card: 0 0% 100%;
    --card-foreground: 210 92% 10%;

    --popover: 0 0% 100%;
    --popover-foreground: 210 92% 10%;

    --primary: 210 92% 10%;
    --primary-foreground: 210 20% 98%;

    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 210 92% 10%;

    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;

    --accent: 220 14.3% 95.9%;
    --accent-foreground: 210 92% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 210 92% 10%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 210 92% 6%;
    --background-light: 210 92% 6%;

    --foreground: 210 20% 98%;
    --light-foreground: 217.9 10.6% 64.9%;
    --highlight-foreground: 208 100% 54%;

    --success: 142 76% 33%;
    --success-foreground: 142 76% 45%;

    --error: 0 62.8% 30.6%;
    --error-foreground: 0 62.8% 50%;

    --card: 210 92% 6%;
    --card-foreground: 210 20% 98%;

    --popover: 210 92% 6%;
    --popover-foreground: 210 20% 98%;

    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;

    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;

    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;

    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;

    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .not-selectable {
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
}

html,
body {
  height: 100%;
}

/* Mapbox */

.mapboxgl-ctrl-logo {
  display: none !important;
}

/* Mapbox Marker */

.marker {
  cursor: pointer;
}

.marker img {
  height: 75px;
}

.marker .wrapper span {
  color: hsl(210 92% 10%);
}

.marker .wrapper span.light {
  color: hsl(210 20% 98%);
}

.marker.secondary img {
  height: 50px;
}

.marker .wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
